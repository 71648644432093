import {Alpine} from "@/alpineJS";
import {ModalAlertType, ModalMinimalType} from "@/modules/modals/modal";
import {i18nProperty} from "@/modules/translations/i18n.property";

export type storageKeys =
  "auth_token"
  | "enquiryFormState"
  | "isPlaceFilter"
  | "email"
  | "auth_callback"
  | "tenure_request"
export const getStorage = <T>(key: storageKeys) => {
  const data = localStorage.getItem(key) ?? null;
  if (!data) return null;
  // check if data is json
  try {
    return JSON.parse(data) as T
  } catch (error) {
    return data as T
  }
}

export const setStorage = (key: storageKeys, value: string) => {
  return localStorage.setItem(key, value)
}

export const numberFormat = (price: string | number) => {
  price = price.toString();
  if (price.length > 0) {
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return "";
}

export const priceShortFormat = (type: string, price_rent: number, price_sale: number) => {
  let price = type === "rent" ? price_rent : price_sale;
  return priceFormat(price)
}

export const textUpperFirstLetter = (text: string) => {
  if (text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return "";
}

export const unSlug = (text: string) => {
  if (text.length > 0) {
    return text.replace(/-/g, ' ');
  }
  return "";
}

export const unSlugUpperFirstLetter = (text: string) => {
  if (text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1).replace(/-/g, ' ');
  }
  return "";
}

export const priceFormat = (price: number) => {
  let reFormatPrice = "";
  if (Math.abs(price) > 999999) {
    reFormatPrice = Math.sign(price) * Number((Math.abs(price) / 1000000).toFixed(1)) + 'M'
  } else if (Math.abs(price) > 999) {
    reFormatPrice = Math.sign(price) * Number((Math.abs(price) / 1000).toFixed(1)) + 'K'
  } else {
    reFormatPrice = numberFormat(Math.sign(price) * Math.abs(price))
  }
  return reFormatPrice;
}

// HELPER function get input value
export const getInputValue = (id: string): string => {
  const input = document.getElementById(id) as HTMLInputElement
  return input?.value || ""
}

// HELPER set input value by id
export const setInputValue = (id: string, value: string) => {
  const input = document.getElementById(id) as HTMLInputElement;
  if (!input) return;
  input.value = value || "";
};

export const setInnerHTMLById = (id: string, text: string) => {
  const ele = document.getElementById(id);
  if (!ele) return;
  ele.innerHTML = text;
}

// HELPER
export const eventListener = (
  ele: HTMLElement | null | Window & typeof globalThis,
  eventType: string,
  cb: (event: any) => any
): void => {
  ele?.addEventListener(eventType, cb);
}

// event listener for multiple elements
export const eventListenerAll = (ele: HTMLCollectionOf<Element>, eventType: string, cb: (event) => void): void => {
  Array.from(ele).forEach((element) => {
    element.addEventListener(eventType, cb);
  });
}

export const getCsrfToken = (): string => {
  const csrfToken = document.getElementById('csrf-token') as HTMLMetaElement
  return csrfToken?.content || "";
}

export const getLocale = (): string => {
  const language = document.getElementById('language') as HTMLMetaElement
  return language?.content || "th-en";
}

export const getLanguage = (): string => {
  const language = document.getElementById('lang') as HTMLMetaElement
  return getLanguages().includes(language?.content) ? language?.content : "en";
}

export const getLanguages = (): string[] => {
  return ['th', 'en', 'ar'];
}

export const checkAuth = (): boolean => !!getStorage<string>('auth_token');

export const moveToEle = (eleId: string, top = 114): void => {
  const targetEle = document.getElementById(eleId) as HTMLElement;
  let position = targetEle.getBoundingClientRect();
  let width = document.documentElement.clientWidth;
  if (width <= 768) {
    top = 8;
  }
  // scrolls to 114px above element
  window.scrollTo({
    top: position.top + window.scrollY - top,
    left: position.left,
    behavior: 'smooth'
  });
};

export const downloadFile = async (url: string, name: string) => {
  // URL of the file to be downloaded
  let fileURL = url;
  // Fetch the file
  const response = await fetch(url);

  // Convert it to a Blob
  const blob = await response.blob();

  // Create an object URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a new anchor element
  let downloadLink = document.createElement('a');

  // Set the href and download attributes of the anchor element
  downloadLink.href = blobUrl;
  downloadLink.target = '_blank';
  // get file extension from url.png
  let extension = url.substring(url.lastIndexOf('.') + 1);
  downloadLink.download = `${name}.${extension}`;  // The filename for download can be given here

  // Append the anchor element to the document body
  document.body.appendChild(downloadLink);

  // Simulate a click on the anchor element
  downloadLink.click();

  // Clean up - remove the anchor element from the document
  document.body.removeChild(downloadLink);
}

export const expandFullScreen = (isExpand: boolean, id = "container-fullscreen") => {
  let elem = document.getElementById(id);
  if (isExpand && elem) {
    const docElmWithBrowsersFullScreenFn = elem as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFn.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFn.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFn.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFn.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFn.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFn.msRequestFullscreen();
    }
  } else {
    const docWithBrowsersExitFn = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (docWithBrowsersExitFn.webkitExitFullscreen) { /* Safari */
      docWithBrowsersExitFn.webkitExitFullscreen();
    } else if (docWithBrowsersExitFn.mozCancelFullScreen) { // Firefox
      docWithBrowsersExitFn.mozCancelFullScreen();
    } else if (docWithBrowsersExitFn.msExitFullscreen) { // IE/Edge
      docWithBrowsersExitFn.msExitFullscreen();
    }
  }
}

export const getCurrencySymbol = () => {
  const locale = getLocale();
  switch (locale) {
    case "th" || "th-en":
      return "฿";
    case "ae":
      return "د.إ";
    case "ae-en":
      return "AED";
    case "sa":
      return "ر.س";
    case "sa-en":
      return "SAR";
    default:
      return "";
  }
}

export const getUnit = () => {
  const locale = getLocale();
  switch (locale) {
    case "ae-en" || "sa-en" || "ae":
      return "Sqft.";
    case "th":
      return "ตร.ม.";
    default:
      return "Sqm.";
  }
}

export const alertThankYouSuccess = (message: string, type: string = '') => {
  const dataStoreAlert = Alpine.store('modalAlert') as ModalAlertType
  dataStoreAlert.toggle(type, message, 'success');
}

export const showModalByElement = (idElement: string, isShow: boolean, isShowCloseBtn = true) => {
  const modal = Alpine.store('modalMinimal') as ModalMinimalType;
  modal.setToggle(idElement, isShow, isShowCloseBtn);
}
